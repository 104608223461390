import React from "react";

const BannerHeading = ({ title, description }) => {
  return (
    <div className="start-time-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="start-time-warp">
              <div className="row">
                <div className="col-lg-12">
                  <div className="fvrt-text">
                    <h2>{title}</h2>
                    <p>{description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerHeading;
