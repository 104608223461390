import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import BannerHeading from "../components/BannerHeading";
import CallToAction from "../components/CallToAction";
import Layout from "../components/Layout";
import PageBanner from "../components/PageBanner";
import Menu from "../components/PizzaMenus/Menu";
import { data } from "../data";
import garlicFingers from "../images/thumb-7.jpg";

const PizzaMenu = () => {
  return (
    <Layout title="Tasty Pizza – Pizza Menu">
      <PageBanner
        title={data.pizzaMenu.pageHeading}
        image={data.pizzaMenu.image}
      />
      <BannerHeading
        title={data.pizzaMenu.pageSubHeading}
        description={data.pizzaMenu.description}
      />
      <div className="favorites-area">
        <div className="container">
          <div className="row">
            <p>
              Toppings: Pepperoni, Salami, Mushroom, Green Pepper, Red Pepper,
              Onions, Red Onions, Bacon, Hamburger, Tomatoes, Pineapple, Italian
              Sausage, Hot Pepper, Black Olives, Green Olives, Donair Meat, Ham
            </p>
          </div>
          <div className="row">
            <div className="col-lg-12 pizzaMenu">
              <Menu menu={data.pizzaMenu.pizzaMenu} />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7 col-md-7">
              <div className="favorites-item-warp">
                <Menu menu={data.pizzaMenu.gourmetPizza} />
                <Menu menu={data.pizzaMenu.extraItems} />
                <Menu menu={data.pizzaMenu.extraCheese} />
              </div>
            </div>
            <div className="col-lg-5 col-md-5">
              <div className="favorite-right">
                <div className="favorite-thumb">
                  <StaticImage
                    src="https://res.cloudinary.com/dzoanfblg/image/upload/v1623696770/Tasty%20Pizza/P7_ywd005.jpg"
                    alt="Tasty Pizza"
                  />
                </div>
                <div className="favorite-thumb">
                  <StaticImage
                    src="https://res.cloudinary.com/dzoanfblg/image/upload/v1623704439/Tasty%20Pizza/P13_ipclal.jpg"
                    alt="Tasty Pizza"
                  />
                </div>
                <div className="favorite-thumb">
                  <CallToAction
                    title="We’re Easy to Find"
                    description="Look us up on our interactive map on the Contact Page"
                    linkText="Get Directions"
                    linkUrl="/contact"
                  />
                </div>
                <div className="favorite-thumb">
                  <StaticImage
                    src="https://res.cloudinary.com/dzoanfblg/image/upload/v1623704330/Tasty%20Pizza/P6_kdlkcc.jpg"
                    alt="Tasty Pizza"
                  />
                </div>
                <div className="favorite-thumb">
                  <StaticImage
                    src="https://res.cloudinary.com/dzoanfblg/image/upload/v1623704558/Tasty%20Pizza/P22_ohtcte.jpg"
                    alt="Tasty Pizza"
                  />
                </div>
                <div className="favorite-thumb">
                  <StaticImage
                    src="https://res.cloudinary.com/dzoanfblg/image/upload/v1623704634/Tasty%20Pizza/P1_fvrfln.jpg"
                    alt="Tasty Pizza"
                  />
                </div>
                <div className="favorite-thumb">
                  <CallToAction
                    title="We’re Easy to Find"
                    description="Look us up on our interactive map on the Contact Page"
                    linkText="Get Directions"
                    linkUrl="/contact"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row bottom-warp my-5">
            <div className="col-lg-6 col-md-7">
              <Menu menu={data.pizzaMenu.garlicMenu} image={garlicFingers} />
            </div>
            <div className="col-lg-6 col-md-7 padding-left">
              <div className="garlic-item garlic-right">
                <h4>Honey Garlic Fingers</h4>
                <StaticImage src="../images/thumb-8.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PizzaMenu;
